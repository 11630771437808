<template>
<div class='uk-offcanvas-content'>
  <div class='OrganizLayout'>

      <!-- header -->
      <message-component></message-component>
      <page-header/>
      <!-- content -->
       <main>
      <slot/>
      </main>

      <!-- //footer -->
      <page-footer/>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Organizcmp/OrganizHeader.vue'
const PageFooter = () => import("../components/Organizcmp/OrganizFooter.vue")
import MessageComponent from '@/components/globals/Notification.vue'
export default {
  name: 'OrganizLayout',
  components: {
    MessageComponent,
    PageHeader,
    PageFooter
  },
  data () {
    return {
      user: null
    }
  },
  created () {
    setTimeout(() => {
      this.user = { name: 'John Doe' }
    }, 2000)
  }
}
</script>

<style scoped>
</style>
