<template>
<layout name="OrganizLayout">
 <div :class="(showMessageBar?'hidden':'hidden') + '  message-bar'">
  <div :class="messageClass">
    <button type="button" bsStyle='link' class="btn btn-link close-btn" @click="closeMessageBar">
      <i class="fa fa-times"></i>
    </Button>
    <div>{{ currentMessage }}</div>
  </div>
</div>
</layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Layout from '../../layouts/Layout'
export default {
  components: {
    Layout
  },
  computed: {
    ...mapGetters(['messages']),
    // showMessageBar () {
    //   if (this.messages.message && this.messages.message.length > 0) {
    //     UIkit.notification({
    //       message: this.messages.message,
    //       status: this.messages.messageClass,
    //       pos: 'top-right',
    //       timeout: 5000
    //     })
    //     this.closeMessageBar
    //   }
    // },
    // messageClass () {
    //   const mgClass = this.messages.messageClass
    //   return (
    //     '' +
    //     mgClass +
    //     ' ' +
    //     (this.showMessageBar ? '' : 'hidden')
    //   )
    // },
    // currentMessage () {
    //   return this.messages.message
    // }
  },
  methods: {
    ...mapActions(['clearMessage']),
    closeMessageBar () {
      this.clearMessage()
    }
  }
}
</script>

<style scoped>
.message-bar {
  position: fixed;
  top: 0;
  left: 50%;
  width: 50%;
  min-height: 50px;
  z-index: 9999;
  margin-left: -25%;
  display: none;
}

.message-bar .close-btn {
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
}

.message-bar .panel {
  border-color: transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-right: 25px;
}

.panel {
  padding: 10px 15px;
}

.panel-danger {
  background-color: #d9534f;
  color: #fff;
}

.panel-success {
  background-color: #5cb85c;
  color: #fff;
}

.panel-warning {
  background-color: #f0ad4e;
  color: #fff;
}
</style>
